body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E7EBF0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.title {
  font-family:'Segoe UI';
  font-weight: 700; 
  color: #333; 
  font-size: 2.5rem; 
}
.task-form__button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chip--todo {
  background-color: #2196f3 !important;
  color: white !important;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 8px 0;
}

.chip--done {
  background-color: green !important;
  color: white !important;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 8px 0;
}

.top-bar {
  background-color: #333;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile__pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.profile__name {
  color: white; 
}

.auth__buttons {
  display: flex;
  align-items: center;
  height: 50px;
}
.profile__details{
  display: flex;
  align-items: center;
  gap: 24px;
}

.button {
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

.button--login {
  background-color: #ffffff; 
  color: #555; 
}

.button--logout {
  background-color: #6e57e0; 
  color: #ffffff; 
  margin-left: 8px; 
}

.button--logout:hover {
  background-color: #7c6fe7; 
}
.button--login:hover {
  background-color:lightgray; 
}
